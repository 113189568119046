
/* jQuery anchor link */
// $(function () {
//   $('a[href^="#"]').on('click', function (event) {
//     var href = $(this).attr('href'),
//       target = $(href == '#' || href == '' ? 'html' : href),
//       position = target.offset().top;
//     $('body, html').animate({scrollTop: position}, 550, 'swing');
//     event.preventDefault();
//   });
// });



/* Check for device type */
var detectDeviceType = function detectDeviceType() {
  return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'Mobile' : 'Desktop'
  );
};

/* Check if element is visible */
var elementIsVisibleInViewport = function elementIsVisibleInViewport(el) {
  var partiallyVisible = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];
  
  var _el$getBoundingClient = el.getBoundingClientRect();
  
  var top = _el$getBoundingClient.top;
  var left = _el$getBoundingClient.left;
  var bottom = _el$getBoundingClient.bottom;
  var right = _el$getBoundingClient.right;
  var _window = window;
  var innerHeight = _window.innerHeight;
  var innerWidth = _window.innerWidth;
  
  return partiallyVisible ? (top > 0 && top < innerHeight || bottom > 0 && bottom < innerHeight) && (left > 0 && left < innerWidth || right > 0 && right < innerWidth) : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
};

$(document).ready(function () {
  function clickClassChanger(openerSelector, blockSelector, closeSelector, activeClass = 'active') {
    openerSelector.click(function() {
      blockSelector.addClass(activeClass)
    })
    closeSelector.click(function() {
      blockSelector.removeClass(activeClass)
    })
  }
  clickClassChanger($('.header-menu-btn'), $('.drawer'), $('.drawer-close'))

  function modalHandler() {
    const links = $('.modal-opener')
    links.each(function() {
      const link = $(this).data('link')
      const modalSelector = link + ', .modal-overlay'
      const close = $(link).find('.modal-close')
      clickClassChanger($(this), $(modalSelector), close)
    })

    $('.modal-overlay').click(function() { $(this).removeClass('active'); $('.modal').removeClass('active') })

  }
  modalHandler()

  function carousel(duration = 3000) {
    const slider = $('.work-slider')
    const arrowLeft = slider.find('.work-slider-arrow--left')
    const arrowRight = slider.find('.work-slider-arrow--right')
    const digit = slider.find('.work-slider-current')
    const total = slider.find('.work-slider-total')
    const imgs = slider.find('.work-slider-img')
    const captions = slider.find('.work-slider-caption')
    
    function setDigitTotal() {
      total.text(imgs.length)
    }
    setDigitTotal()

    function changeDigit(index) {
      digit.text(index)
    }

    function moveTo(index) {
      imgs.removeClass('active')
      imgs.eq(index).addClass('active')
      captions.removeClass('active')
      captions.eq(index).addClass('active')

      changeDigit(index + 1)
    }

    function moveRight() {
      const activeImg = slider.find('.work-slider-img.active')
      const index = imgs.index(activeImg)
      if (imgs.eq(index) && index !== imgs.length - 1) {
        moveTo(index + 1)
      } else {
        moveTo(0)
      }
    }

    function moveLeft() {
      const activeImg = slider.find('.work-slider-img.active')
      const index = imgs.index(activeImg)
      if (imgs.eq(index) && index !== 0) {
        moveTo(index - 1)
      } else {
        moveTo(imgs.length - 1)
      }
    }

    arrowRight.click(moveRight)
    arrowLeft.click(moveLeft)
  }
  carousel()

  function scrollIn(block, x, $class) {
    const wTop = $(window).scrollTop(),
        wHeight = $(window).height(),
        dist = x || 100,
        $classN = $class || "fade-in";

    block.each(function () {
        const bTop = $(this).offset().top;
        let playAt = 0;
        if ($(this).data('delay')) {
            playAt = bTop - wHeight + dist + parseInt($(this).data('delay'), 10);
        } else {
            playAt = bTop - wHeight + dist
        }
        playAt < wTop ? $(this).addClass($classN) : false;
    });
  };
  scrollIn($('.section'), 150, 'scrolled');
  if (detectDeviceType() === 'Desktop') {
    $('.about-number-digit').text('1')
    new fullpage('#fullpage', {
      scrollOverflow: true,
      afterLoad: function(origin, destination) {
        $(destination.item).addClass('scrolled')
        if ($(destination.item).hasClass('about')) {
            $('.about-number-digit').each(function () {
              if (!$(this).hasClass('scrolled')) {
                $(this).prop('Counter', 1).animate({
                    Counter: $(this).data('digit')
                  }, {
                  duration: 1000,
                  easing: 'swing',
                  step: function (now) {                      
                      $(this).text(this.Counter.toFixed(0));
                  }
                });
                $(this).addClass('scrolled')
              }
          });
        }
      }
    });

    $('#nav').find('a').click(function() {
      const index = parseInt($(this).data('section'))
      fullpage_api.moveTo(index)
      return false
    })
  }

  $(window).on('scroll', function () {
    scrollIn($('.section'), 150, 'scrolled');
  });
});